body {
  background-color: rgb(10, 25, 41);
  min-height: 100vh;
}

.data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 0.5vmin);
  color: white;

}

.appbar {
  background-color: rgb(0, 30, 60);
}

.hidden {
  opacity: 0;
}

.parceiros {
  width: 100%;
  background-color: white;
  max-height: 10vh;
  transition: opacity 0.3s ;
  z-index: 2;
  position:relative;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}